import React, { useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { nationInfo } from '@/constants/refundDetail';
import { useAppSelector } from '@/hooks/useReduxHooks';
import {
	formattedRefundStatus,
	getComma,
	formatDateRefundStatement,
} from '@/util/common';
import { getAgeSection, getPaymentMethod } from '@/util/home';
import QuestionIcon from '@/assets/common/question.png';
import { RefundConentType } from '@/types/home';

type refundDetailGraphItemType = {
	item: RefundConentType;
	idx: number;
	pageNumber: number;
	totalElements: number;
};

function RefundDetailGraphItem({
	item,
	idx,
	pageNumber,
	totalElements,
}: refundDetailGraphItemType) {
	const { size } = useAppSelector((state) => state.refund);
	const [isActiveTooltip, setIsActiveTooltip] = useState(false);
	const nation =
		nationInfo?.[item.customerNation]?.label || item?.customerNation || '-';

	return (
		<Item>
			<Content flex={1}>{totalElements - (pageNumber * size + idx)}</Content>{' '}
			<Content flex={2.2}>{item.employeeName ?? '본사'}</Content>
			<Content flex={1.4}>
				{item.refundStatus === 'CHECK' && (
					<ContentImg
						src={QuestionIcon}
						onClick={() => setIsActiveTooltip(!isActiveTooltip)}
					/>
				)}

				{formattedRefundStatus(item.refundStatus)}
				{isActiveTooltip && (
					<Tooltip>
						<Arrow />
						<TooltipContent>
							해당 고객께서 출국 시 세관 확인이 마무리되면 승인 상태로 바뀌며
							<br />
							확인이 제대로 이루어지지 않을 경우 취소로 환급상태가 변경됩니다.
						</TooltipContent>
					</Tooltip>
				)}
			</Content>
			<Content flex={1.5}>
				{formatDateRefundStatement(
					item.refundStatus,
					new Date(item.createdDate)
				)}
			</Content>
			<Content flex={2}>
				{format(new Date(item.createdDate), 'yyyy.MM.dd HH:mm')}
			</Content>
			<Content flex={2}>{getComma(item.totalAmount)}</Content>
			<Content flex={2}>{getComma(item.totalRefund)}</Content>
			<Content flex={2}>{getComma(item.actualAmount)}</Content>
			<Content flex={0.7}>
				{item.gender ? (item.gender === 'M' ? '남성' : '여성') : '-'}
			</Content>
			<Content flex={1.5}>
				{nation.length > 4 ? nation.substr(0, 4) + '..' : nation}
			</Content>
			<Content flex={1}>{item.age ? getAgeSection(item.age) : '-'}</Content>
			<Content flex={1.5}>
				{item.paymentMethod ? getPaymentMethod(item.paymentMethod) : '-'}
			</Content>
		</Item>
	);
}
const Item = styled.li`
	display: flex;
	justify-content: center;
	border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
	background-color: ${(props) => props.theme.pointColors.white};
	:hover {
		background-color: #f7f9fc;
	}
`;

const Content = styled.div<{ flex: number }>`
	position: relative;
	display: flex;
	flex: ${(props) => props.flex};
	text-align: center;
	justify-content: center;
	align-items: center;
	padding: 12px 20px;
	gap: 4px;
	border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
	:first-child {
		border-left: 1px solid ${(props) => props.theme.grayColors.scale3};
	}
`;
const ContentImg = styled.img`
	width: 24px;
	height: 24px;
	cursor: pointer;
`;
const Tooltip = styled.div`
	position: absolute;
	top: 100%;
	left: 10%;
	background-color: #212121;
	width: 400px;
	z-index: 10;
	border-radius: 4px;
	padding: 8px 12px;
`;
const TooltipContent = styled.p`
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
	color: #ffffff;
`;
const Arrow = styled.div`
	position: absolute;
	top: -20px;
	left: 14%;
	width: 10px;
	height: 11px;
	border-top: 8px solid transparent;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 12px solid #212121;
	@media ${(props) => props.theme.laptop} {
		left: 3%;
	}
`;
export default RefundDetailGraphItem;
