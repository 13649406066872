export const RefundDetailGraphHeaderContent = [
	{ id: 1, title: 'NO.', flex: 1 },
	{ id: 2, title: '직원', flex: 2.2 },
	{ id: 3, title: '환급상태', flex: 1.4 },
	{ id: 4, title: '실적명세서반영일', flex: 1.5 },
	{ id: 5, title: '판매일자', flex: 2 },
	{ id: 6, title: '판매금액', flex: 2 },
	{ id: 7, title: '환급액', flex: 2 },
	{ id: 8, title: '고객결제금액', flex: 2 },
	{ id: 9, title: '성별', flex: 0.7 },
	{ id: 10, title: '국적', flex: 1.5 },
	{ id: 11, title: '연령', flex: 1 },
	{ id: 12, title: '결제수단', flex: 1.5 },
];
export const refundStatusSelector = [
	{ label: '전체', value: null },
	{ label: '승인', value: 'APPROVAL' },
	{ label: '승인 대기', value: 'PRE_APPROVAL' },
	{ label: '취소', value: 'CANCEL' },
	{ label: '선별검사', value: 'CHECK' },
];
export const genderSelector = [
	{ value: null, label: '전체' },
	{ value: 'M', label: '남성' },
	{ value: 'F', label: '여성' },
];
export const ageSelector = [
	{ value: { minAge: 0, maxAge: 100 }, label: '전체' },
	{ value: { minAge: 0, maxAge: 19 }, label: '0~19' },
	{ value: { minAge: 20, maxAge: 29 }, label: '20~29' },
	{ value: { minAge: 30, maxAge: 39 }, label: '30~39' },
	{ value: { minAge: 40, maxAge: 49 }, label: '40~49' },
	{ value: { minAge: 50, maxAge: 59 }, label: '50~59' },
	{ value: { minAge: 60, maxAge: 69 }, label: '60~69' },
	{ value: { minAge: 70, maxAge: 100 }, label: '70~100' },
];
export const nationInfo: any = {
	AUS: { value: 'AUS', label: '오스트레일리아' },
	BEL: { value: 'BEL', label: '벨기에' },
	BGD: { value: 'BGD', label: '방글라데시' },
	BRA: { value: 'BRA', label: '브라질' },
	CAN: { value: 'CAN', label: '캐나다' },
	CHE: { value: 'CHE', label: '스위스' },
	CHL: { value: 'CHL', label: '칠레' },
	CHN: { value: 'CHN', label: '중국' },
	COL: { value: 'COL', label: '콜롬비아' },
	CUB: { value: 'CUB', label: '쿠바' },
	DEU: { value: 'DEU', label: '독일' },
	ESP: { value: 'ESP', label: '스페인' },
	FJI: { value: 'FJI', label: '피지' },
	FRA: { value: 'FRA', label: '프랑스' },
	GBR: { value: 'GBR', label: '영국' },
	HKG: { value: 'HKG', label: '홍콩' },
	IDN: { value: 'IDN', label: '인도네시아' },
	IND: { value: 'IND', label: '인도' },
	ITA: { value: 'ITA', label: '이탈리아' },
	JPN: { value: 'JPN', label: '일본' },
	KAZ: { value: 'KAZ', label: '카자흐스탄' },
	KIR: { value: 'KIR', label: '키리바시' },
	KOR: { value: 'KOR', label: '한국' },
	LKA: { value: 'LKA', label: '스리랑카' },
	MEX: { value: 'MEX', label: '멕시코' },
	MMR: { value: 'MMR', label: '미얀마' },
	NLD: { value: 'NLD', label: '네덜란드' },
	NOR: { value: 'NOR', label: '노르웨이' },
	NZL: { value: 'NZL', label: '뉴질랜드' },
	PAK: { value: 'PAK', label: '파키스탄' },
	PER: { value: 'PER', label: '페루' },
	PHL: { value: 'PHL', label: '필리핀' },
	POL: { value: 'POL', label: '폴란드' },
	RUS: { value: 'RUS', label: '러시아' },
	SAU: { value: 'SAU', label: '사우디아라비아' },
	SGP: { value: 'SGP', label: '싱가포르' },
	SWE: { value: 'SWE', label: '스웨덴' },
	THA: { value: 'THA', label: '태국' },
	TUR: { value: 'TUR', label: '튀르키예' },
	TWN: { value: 'TWN', label: '대만' },
	UKR: { value: 'UKR', label: '우크라이나' },
	USA: { value: 'USA', label: '미국' },
	UZB: { value: 'UZB', label: '우즈베키스탄' },
	VNM: { value: 'VNM', label: '베트남' },
	ZAF: { value: 'ZAF', label: '남아프리카공화국' },
	AND: { value: 'AND', label: '안도라' },
	ARE: { value: 'ARE', label: '아랍에미리트' },
	AFG: { value: 'AFG', label: '아프가니스탄' },
	ATG: { value: 'ATG', label: '앤티가바부다' },
	AIA: { value: 'AIA', label: '앵귈라' },
	ALB: { value: 'ALB', label: '알바니아' },
	ARM: { value: 'ARM', label: '아르메니아' },
	AGO: { value: 'AGO', label: '앙골라' },
	ATA: { value: 'ATA', label: '남극' },
	ARG: { value: 'ARG', label: '아르헨티나' },
	ASM: { value: 'ASM', label: '아메리칸사모아' },
	AUT: { value: 'AUT', label: '오스트리아' },
	ABW: { value: 'ABW', label: '아루바' },
	ALA: { value: 'ALA', label: '올란드제도' },
	AZE: { value: 'AZE', label: '아제르바이잔' },
	BIH: { value: 'BIH', label: '보스니아헤르체고비나' },
	BRB: { value: 'BRB', label: '바베이도스' },
	BFA: { value: 'BFA', label: '부르키나파소' },
	BGR: { value: 'BGR', label: '불가리아' },
	BHR: { value: 'BHR', label: '바레인' },
	BDI: { value: 'BDI', label: '부룬디' },
	BEN: { value: 'BEN', label: '베냉' },
	BLM: { value: 'BLM', label: '생바르텔레미' },
	BMU: { value: 'BMU', label: '버뮤다' },
	BRN: { value: 'BRN', label: '브루나이' },
	BOL: { value: 'BOL', label: '볼리비아' },
	BES: { value: 'BES', label: '보네르섬' },
	BHS: { value: 'BHS', label: '바하마' },
	BTN: { value: 'BTN', label: '부탄' },
	BVT: { value: 'BVT', label: '부베섬' },
	BWA: { value: 'BWA', label: '보츠와나' },
	BLR: { value: 'BLR', label: '벨라루스' },
	BLZ: { value: 'BLZ', label: '벨리즈' },
	CCK: { value: 'CCK', label: '코코스제도' },
	COD: { value: 'COD', label: '콩고민주공화국' },
	CAF: { value: 'CAF', label: '중앙아프리카공화국' },
	COG: { value: 'COG', label: '콩고공화국' },
	CIV: { value: 'CIV', label: '코트디부아르' },
	COK: { value: 'COK', label: '쿡제도' },
	CMR: { value: 'CMR', label: '카메룬' },
	CRI: { value: 'CRI', label: '코스타리카' },
	CPV: { value: 'CPV', label: '카보베르데' },
	CUW: { value: 'CUW', label: '퀴라소' },
	CXR: { value: 'CXR', label: '크리스마스섬' },
	CYP: { value: 'CYP', label: '키프로스' },
	CZE: { value: 'CZE', label: '체코' },
	DJI: { value: 'DJI', label: '지부티' },
	DNK: { value: 'DNK', label: '덴마크' },
	DMA: { value: 'DMA', label: '도미니카 연방' },
	DOM: { value: 'DOM', label: '도미니카 공화국' },
	DZA: { value: 'DZA', label: '알제리' },
	ECU: { value: 'ECU', label: '에콰도르' },
	EST: { value: 'EST', label: '에스토니아' },
	EGY: { value: 'EGY', label: '이집트' },
	ESH: { value: 'ESH', label: '서사하라' },
	ERI: { value: 'ERI', label: '에리트레아' },
	ETH: { value: 'ETH', label: '에티오피아' },
	FIN: { value: 'FIN', label: '핀란드' },
	FLK: { value: 'FLK', label: '포클랜드 제도' },
	FSM: { value: 'FSM', label: '미크로네시아 연방' },
	FRO: { value: 'FRO', label: '페로 제도' },
	GAB: { value: 'GAB', label: '가봉' },
	GRD: { value: 'GRD', label: '그레나다' },
	GEO: { value: 'GEO', label: '조지아' },
	GUF: { value: 'GUF', label: '프랑스령 기아나' },
	GGY: { value: 'GGY', label: '건지섬' },
	GHA: { value: 'GHA', label: '가나' },
	GIB: { value: 'GIB', label: '지브롤터' },
	GRL: { value: 'GRL', label: '그린란드' },
	GMB: { value: 'GMB', label: '감비아' },
	GIN: { value: 'GIN', label: '기니' },
	GLP: { value: 'GLP', label: '과들루프' },
	GNQ: { value: 'GNQ', label: '적도 기니' },
	GRC: { value: 'GRC', label: '그리스' },
	SGS: { value: 'SGS', label: '사우스조지아 사우스샌드위치 제도' },
	GTM: { value: 'GTM', label: '과테말라' },
	GUM: { value: 'GUM', label: '괌' },
	GNB: { value: 'GNB', label: '기니비사우' },
	GUY: { value: 'GUY', label: '가이아나' },
	HAR: { value: 'HAR', label: '하라르' },
	HMD: { value: 'HMD', label: '허드 맥도널드 제도' },
	HND: { value: 'HND', label: '온두라스' },
	HRV: { value: 'HRV', label: '크로아티아' },
	HTI: { value: 'HTI', label: '아이티' },
	HUN: { value: 'HUN', label: '헝가리' },
	IRL: { value: 'IRL', label: '아일랜드' },
	ISR: { value: 'ISR', label: '이스라엘' },
	IMN: { value: 'IMN', label: '맨섬' },
	IOT: { value: 'IOT', label: '영국령인도양지역' },
	IRQ: { value: 'IRQ', label: '이라크' },
	IRN: { value: 'IRN', label: '이란' },
	ISL: { value: 'ISL', label: '아이슬란드' },
	JEY: { value: 'JEY', label: '저지섬' },
	JAM: { value: 'JAM', label: '자메이카' },
	JOR: { value: 'JOR', label: '요르단' },
	KEN: { value: 'KEN', label: '케냐' },
	KGZ: { value: 'KGZ', label: '키르기스스탄' },
	KHM: { value: 'KHM', label: '캄보디아' },
	COM: { value: 'COM', label: '코모로' },
	KNA: { value: 'KNA', label: '세인트키츠네비스' },
	PRK: { value: 'PRK', label: '조선민주주의인민공화국' },
	KWT: { value: 'KWT', label: '쿠웨이트' },
	CYM: { value: 'CYM', label: '케이맨제도' },
	LAO: { value: 'LAO', label: '라오스' },
	LBN: { value: 'LBN', label: '레바논' },
	LCA: { value: 'LCA', label: '세인트루시아' },
	LIE: { value: 'LIE', label: '리히텐슈타인' },
	LBR: { value: 'LBR', label: '라이베리아' },
	LSO: { value: 'LSO', label: '레소토' },
	LTU: { value: 'LTU', label: '리투아니아' },
	LUX: { value: 'LUX', label: '룩셈부르크' },
	LVA: { value: 'LVA', label: '라트비아' },
	LBY: { value: 'LBY', label: '리비아' },
	MAR: { value: 'MAR', label: '모로코' },
	MCO: { value: 'MCO', label: '모나코' },
	MDA: { value: 'MDA', label: '몰도바' },
	MNE: { value: 'MNE', label: '몬테네그로' },
	MAF: { value: 'MAF', label: '생마르탱 공동체' },
	MDG: { value: 'MDG', label: '마다가스카르' },
	MHL: { value: 'MHL', label: '마셜 제도' },
	MKD: { value: 'MKD', label: '북마케도니아' },
	MLI: { value: 'MLI', label: '말리' },
	MNG: { value: 'MNG', label: '몽골' },
	MAC: { value: 'MAC', label: '마카오' },
	MNP: { value: 'MNP', label: '북마리아나제도' },
	MTQ: { value: 'MTQ', label: '마르티니크' },
	MRT: { value: 'MRT', label: '모리타니' },
	MSR: { value: 'MSR', label: '몬트세랫' },
	MLT: { value: 'MLT', label: '몰타' },
	MUS: { value: 'MUS', label: '모리셔스' },
	MDV: { value: 'MDV', label: '몰디브' },
	MWI: { value: 'MWI', label: '말라위' },
	MYS: { value: 'MYS', label: '말레이시아' },
	MOZ: { value: 'MOZ', label: '모잠비크' },
	NAM: { value: 'NAM', label: '나미비아' },
	NCL: { value: 'NCL', label: '누벨칼레도니' },
	NER: { value: 'NER', label: '니제르' },
	NFK: { value: 'NFK', label: '노퍽섬' },
	NGA: { value: 'NGA', label: '나이지리아' },
	NIC: { value: 'NIC', label: '니카라과' },
	NPL: { value: 'NPL', label: '네팔' },
	NRU: { value: 'NRU', label: '나우루' },
	NIU: { value: 'NIU', label: '니우에' },
	OMN: { value: 'OMN', label: '오만' },
	PAN: { value: 'PAN', label: '파나마' },
	PYF: { value: 'PYF', label: '프랑스령폴리네시아' },
	PNG: { value: 'PNG', label: '파푸아뉴기니' },
	SPM: { value: 'SPM', label: '생피에르미클롱' },
	PCN: { value: 'PCN', label: '핏케언제도' },
	PRI: { value: 'PRI', label: '푸에르토리코' },
	PSE: { value: 'PSE', label: '팔레스타인' },
	PRT: { value: 'PRT', label: '포르투갈' },
	PLW: { value: 'PLW', label: '팔라우' },
	PRY: { value: 'PRY', label: '파라과이' },
	QAT: { value: 'QAT', label: '카타르' },
	REU: { value: 'REU', label: '레위니옹' },
	ROU: { value: 'ROU', label: '루마니아' },
	SRB: { value: 'SRB', label: '세르비아' },
	RWA: { value: 'RWA', label: '르완다' },
	SLB: { value: 'SLB', label: '솔로몬제도' },
	SYC: { value: 'SYC', label: '세이셸' },
	SDN: { value: 'SDN', label: '수단' },
	SHN: { value: 'SHN', label: '세인트헬레나' },
	SVN: { value: 'SVN', label: '슬로베니아' },
	SJM: { value: 'SJM', label: '스발바르얀마옌제도' },
	SVK: { value: 'SVK', label: '슬로바키아' },
	SLE: { value: 'SLE', label: '시에라리온' },
	SMR: { value: 'SMR', label: '산마리노' },
	SEN: { value: 'SEN', label: '세네갈' },
	SOM: { value: 'SOM', label: '소말리아' },
	SUR: { value: 'SUR', label: '수리남' },
	SSD: { value: 'SSD', label: '남수단' },
	STP: { value: 'STP', label: '상투메프린시페' },
	SLV: { value: 'SLV', label: '엘살바도르' },
	SXM: { value: 'SXM', label: '신트마르턴' },
	SYR: { value: 'SYR', label: '시리아' },
	SWZ: { value: 'SWZ', label: '에스와티니' },
	TCA: { value: 'TCA', label: '터크스케이커스제도' },
	TCD: { value: 'TCD', label: '차드' },
	ATF: { value: 'ATF', label: '프랑스령남방및남극지역' },
	TGO: { value: 'TGO', label: '토고' },
	TJK: { value: 'TJK', label: '타지키스탄' },
	TKL: { value: 'TKL', label: '토켈라우' },
	TLS: { value: 'TLS', label: '동티모르' },
	TKM: { value: 'TKM', label: '투르크메니스탄' },
	TUN: { value: 'TUN', label: '튀니지' },
	TON: { value: 'TON', label: '통가' },
	TTO: { value: 'TTO', label: '트리니다드토바고' },
	TUV: { value: 'TUV', label: '투발루' },
	TZA: { value: 'TZA', label: '탄자니아' },
	UGA: { value: 'UGA', label: '우간다' },
	UMI: { value: 'UMI', label: '미국령군소제도' },
	URY: { value: 'URY', label: '우루과이' },
	VAT: { value: 'VAT', label: '바티칸시국' },
	VCT: { value: 'VCT', label: '세인트빈센트그레나딘' },
	VEN: { value: 'VEN', label: '베네수엘라' },
	VGB: { value: 'VGB', label: '영국령버진아일랜드' },
	VIR: { value: 'VIR', label: '미국령버진아일랜드' },
	VUT: { value: 'VUT', label: '바누아투' },
	WLF: { value: 'WLF', label: '왈리스푸투나' },
	WSM: { value: 'WSM', label: '사모아' },
	YEM: { value: 'YEM', label: '예멘' },
	MYT: { value: 'MYT', label: '마요트' },
	ZMB: { value: 'ZMB', label: '잠비아' },
	ZWE: { value: 'ZWE', label: '짐바브웨' },
};
export const paymentSelector = [
	{ value: null, label: '전체' },
	{ value: 'CARD', label: '카드' },
	{ value: 'CASH', label: '현금' },
	{ value: 'GLOBAL', label: '글로벌 결제' },
];
